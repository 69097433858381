import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import Impressum from "./components/Pages/Impressum";
import Datenschutzerklaerung from "./components/Pages/Datenschutzerklaerung";
import Kontakt from "./components/Pages/Kontakt";
import News_Overview from "./components/Pages/News_Overview";
import News_1 from "./components/Pages/News_1";
import News_2 from "./components/Pages/News_2";
import News_3 from "./components/Pages/News_3";
import News_4 from "./components/Pages/News_4";
import News_5 from "./components/Pages/News_5";
import News_6 from "./components/Pages/News_6";
import News_7 from "./components/Pages/News_7";
import Formulare from "./components/Pages/Formulare";
import Glossar from "./components/Pages/Glossar";
import Familienrecht from "./components/Pages/Familienrecht";
import Migrationsrecht from "./components/Pages/Migrationsrecht";
import Anwaltlicher_Notdienst from "./components/Pages/Anwaltlicher_Notdienst";
import { Helmet } from 'react-helmet';
import FAQ from "./components/Pages/FAQ";
import { useEffect } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import News_8 from "./components/Pages/News_8";
import News_9 from "./components/Pages/News_9";
import News_10 from "./components/Pages/News_10";
import News_11 from "./components/Pages/News_11";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Zubehör",
    };
    this.handler = this.handler.bind(this)
  }

  componentDidMount() {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {}
      },

      language: {
        default: 'de',
        translations: {
          de: {
            consentModal: {
              title: 'Verwendung von Cookies und vergleichbaren Technologien',
              description: 'Wir arbeiten kontinuierlich daran, unsere Angebote zu optimieren und besser auf Ihre Interessen sowie Ihr Nutzungsverhalten abzustimmen. Zu diesem Zweck möchten wir – vorbehaltlich Ihrer Zustimmung – bestimmte Informationen auf Ihrem Gerät speichern bzw. auslesen und Ihre personenbezogenen Daten (etwa IP-Adresse oder technische Kennungen) verarbeiten. Teilweise werden Ihre Daten dabei auch von externen Dienstleistern oder in Ländern außerhalb des Europäischen Wirtschaftsraums verarbeitet. In den Einstellungen zum Datenschutz erfahren Sie Näheres über die jeweiligen Verarbeitungszwecke und können auswählen, für welche Zwecke Sie Ihre Einwilligung erteilen möchten. Ihre Zustimmung ist freiwillig und kann jederzeit mit Wirkung für die Zukunft widerrufen werden.',
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              showPreferencesBtn: 'Individuell konfigurieren'
            },
            preferencesModal: {
              title: 'Individuell konfigurieren',
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              savePreferencesBtn: 'Konfiguration akzeptieren',
              closeIconLabel: 'Dialog schließen',
              sections: [
                {
                  title: 'Diese Webseite verwendet Cookies, um die Benutzererfahrung zu verbessern.',
                  description: ''
                },
                {
                  title: 'Cookies',
                  description: 'Erlauben Sie die Verwendung von Cookies, um die Benutzererfahrung auf dieser Webseite zu verbessern.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Weitere Informationen',
                  description: 'Weitere Informationen finden Sie unter folgendem Link: <a target="_blank" href="/de/datenschutzerklaerung">Datenschutzerklärung</a>.'
                }
              ]
            }
          }
        }
      }
    });
  }

  handler(newActive) {
    this.setState({
      active: newActive
    })
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <script async src="https://widget.anwalt.de/anwalt-de-profil/12/id/234680/baseColor/385956/headerColor/ffffff/width/650/rounded/0/linksNewWindow/1/disableBorder/0/get.js?uid=1dfea57d25a3&v=2"></script>
          <script async src="https://widget.anwalt.de/bewertung-abgeben/16/id/234680/baseColor/385956/buttonColor/e9503e/headerColor/ffffff/width/400/rounded/0/linksNewWindow/1/disableBorder/0/get.js?uid=02136cfdf123&v=2"></script>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/de/blog" element={<News_Overview />} />
          <Route path="/de/familienrecht" element={<Familienrecht />} />
          <Route path="/de/migrationsrecht" element={<Migrationsrecht />} />
          <Route path="/de/pflichtanwalt" element={<Anwaltlicher_Notdienst />} />
          <Route path="/de/blog/abschiebungen-deutschland-2024-kritische-betrachtung" element={<News_1 />} />
          <Route path="/de/blog/darf-ich-eigenstaendig-aus-fluechtlingsunterkunft-ausziehen" element={<News_2 />} />
          <Route path="/de/blog/was-kommt-bei-scheidung-auf-mich-zu-leitfaden-durch-prozess" element={<News_3 />} />
          <Route path="/de/blog/erwerbsobliegenheiten-bei-scheidung-ab-wann-muss-ich-arbeiten" element={<News_4 />} />
          <Route path="/de/blog/haftbeschwerde" element={<News_5 />} />
          <Route path="/de/blog/neues-staatsangehoerigkeitsgesetz" element={<News_6 />} />
          <Route path="/de/blog/wann-steht-mir-trennungsunterhalt-zu" element={<News_7 />} />
          <Route path="/de/blog/das-beschleunigte-fachkraefteeinwanderungsverfahren-nach-deutschem-recht-chancen-herausforderungen" element={<News_8 />} />
          <Route path="/de/blog/zwangsvollstreckung-von-kindesunterhalt-nach-deutschem-recht" element={<News_9 />} />
          <Route path="/de/blog/einreise-und-aufenthaltsverbot-verkuerzen-oder-aufheben" element={<News_10 />} />
          <Route path="/de/blog/spurwechsel-im-auslaenderrecht-chancen-und-herausforderungen" element={<News_11 />} />
          <Route path="/de/kontakt" element={<Kontakt />} />
          <Route path="/de/formulare" element={<Formulare />} />
          <Route path="/de/glossar" element={<Glossar />} />
          <Route path="/de/faq" element={<FAQ />} />
          <Route path="/de/kanzlei" element={<AboutUs />} />
          <Route path="/de/impressum" element={<Impressum />} />
          <Route path="/de/datenschutzerklaerung" element={<Datenschutzerklaerung />} />
        </Routes>
      </div>
    );
  }
}

export default App;
