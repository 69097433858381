import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class Datenschutzerklaerung extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Datenschutzerklärung</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Datenschutzerklärung" />
          <meta
            property="og:description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
        </MetaTags>
        <NavDE />
        <div className="section flex first intro center-h less-bot imp">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="argesto">Datenschutzerklärung</h2>
            </div>
            <div className="flex col">
              <p className="body">
                <strong>
                  1. Geltungsbereich<br></br>
                </strong>
                Diese Datenschutzerklärung gilt für das Internetangebot der Kanzlei Elisabeth Lutz, die unter
                der Domain{" "}
                <a href="http://www.kanzlei-e-lutz.de/">http://www.kanzlei-e-lutz.de</a>{" "}
                abrufbar ist.
              </p>
              <p className="body">
                &zwj;<br></br>
                <strong>
                  2. Information über die Erhebung personenbezogener Daten
                  <br></br>
                </strong>
                Personenbezogene Daten sind Informationen, die dazu genutzt
                werden können, persönliche oder sachliche Verhältnisse über Sie
                zu erfahren (z.B. Name, Anschrift, Telefonnummer, Geburtsdatum
                oder E-Mail-Adresse). Informationen, bei denen wir keinen (oder
                nur mit einem unverhältnismäßigen Aufwand) Bezug zu Ihrer Person
                herstellen können, z.B. durch Anonymisierung der Informationen,
                sind keine personenbezogenen Daten.<br></br>
                <br></br>Verantwortlicher gem. Art. 4 Abs. 7
                EU-Datenschutz-Grundverordnung (DS-GVO) ist die Rechtsanwältin Elisabeth Lutz. Telefonisch
                sind wir unter der Nummer 0711 - 2364402 erreichbar.
              </p>
              <p className="body">
                Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
                Kontaktformular werden die von Ihnen mitgeteilten Daten bei uns
                gespeichert, um Ihre<br></br>Fragen zu beantworten. Die in
                diesem Zusammenhang anfallenden Daten löschen wir, nachdem die
                Speicherung nicht mehr erforderlich ist,<br></br>oder schränken
                die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
                bestehen.
              </p>
              <p className="body">
                Falls wir für einzelne Funktionen unseres Angebots auf
                beauftragte Dienstleister zurückgreifen oder Ihre Daten für
                werbliche Zwecke nutzen möchten,<br></br>werden wir Sie unten
                stehend im Detail über die jeweiligen Vorgänge informieren.
                Dabei nennen wir auch die festgelegten Kriterien der
                Speicherdauer.
              </p>
              <br></br>
              <p className="body">
                <strong>
                  3. Ihre Rechte<br></br>
                </strong>
                Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
                betreffenden personenbezogenen Daten:
              </p>
              <ul role="list">
                <li>Recht auf Auskunft</li>
                <li>Recht auf Berichtigung oder Löschung,</li>
                <li>Recht auf Einschränkung der Verarbeitung,</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>Recht auf Datenübertragbarkeit.</li>
              </ul>
              <p className="body">
                Sie haben zudem das Recht, sich bei einer
                Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten<br></br> durch uns zu beschweren.
              </p>
              <br></br>
              <p className="body">
                <strong>4.</strong>{" "}
                <strong>
                  Erhebung personenbezogener Daten bei Besuch unserer Website
                  <br></br>
                </strong>
                Bei der bloß informatorischen Nutzung der Website, also wenn Sie
                sich nicht registrieren oder uns anderweitig Informationen
                übermitteln,<br></br>erheben wir nur die personenbezogenen
                Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie
                unsere Website betrachten möchten,<br></br>erheben wir die
                folgenden Daten, die für uns technisch erforderlich sind, um
                Ihnen unsere Website anzuzeigen und die Stabilität und
                Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1
                S. 1 lit. b, lit. f DS-GVO):
              </p>
              <ul role="list">
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils übertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfläche</li>
                <li>Sprache und Version der Browsersoftware.</li>
              </ul>
              <br></br>
              <p className="body">
                <strong>
                  5. Sicherungsmaßnahmen zum Schutz der bei uns gespeicherten
                  Daten<br></br>
                </strong>
                Personenbezogene Daten, die Sie uns über unsere Website und im
                E-Mail-Verkehr anvertrauen, sind sicher. Wir verpflichten uns,
                Ihre Privatsphäre zu schützen und Ihre personenbezogenen Daten
                vertraulich zu behandeln. Wir weisen Sie jedoch darauf hin, dass es aufgrund der Struktur
                des Internets möglich ist, dass die Regeln des Datenschutzes und
                <br></br>die o. g. Sicherungsmaßnahmen von anderen, nicht im
                Verantwortungsbereich von uns liegenden Personen oder
                Institutionen<br></br>nicht beachtet werden. Insbesondere können
                unverschlüsselt preisgegebene Daten – auch wenn dies per E-Mail
                erfolgt – von Dritten<br></br>mitgelesen werden. Wir haben
                technisch hierauf keinen Einfluss. Es liegt im
                Verantwortungsbereich des Nutzers, die von ihm zur<br></br>
                Verfügung gestellten Daten durch Verschlüsselung oder in
                sonstiger Weise gegen Missbrauch zu schützen.
                <br></br><br></br>
              </p>
              <p className="body">
                <strong>
                  6. Server-Logs<br></br>
                </strong>
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                <ul role="list">
                  <li>Browsertyp / BrowserversionVerwendetes Betriebssystem</li>
                  <li>Referrer URL</li>
                  <li>Hostname des zugreifenden Rechners</li>
                  <li>Uhrzeit der Serveranfrage</li>
                </ul>
                Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
              </p>
              <p className="body">
                Um einen Verlust oder Missbrauch der bei uns gespeicherten Daten
                zu vermeiden, treffen wir umfangreiche technische und<br></br>
                betriebliche Sicherheitsvorkehrungen, die regelmäßig überprüft
                und dem technologischen Fortschritt angepasst werden.<br></br>
              </p>
              <br></br>
              <p className="body">
                <strong>
                  7. Hyperlinks zu fremden Websites<br></br>
                </strong>
                Auf unserer Website können sich sog. Hyperlinks zu Websites
                anderer Anbieter befinden. Bei Aktivierung dieser Hyperlinks
                werden sie von unserer Website direkt auf die Website der
                anderen Anbieter weitergeleitet. Sie erkennen dies u. a. am
                Wechsel der URL. Wir können keine Verantwortung für den
                vertraulichen Umgang Ihrer Daten auf diesen Websites Dritter
                übernehmen, da wir keinen Einfluss darauf haben, dass diese
                Unternehmen die Datenschutzbestimmungen einhalten. Über den
                Umgang mit Ihren personenbezogenen Daten durch diese Unternehmen
                informieren Sie sich bitte auf diesen Websites direkt.
              </p>
              <br></br>
              <p className="body">
                <strong>
                  8. Auskunft zu Ihren bei uns gespeicherten personenbezogenen
                  Daten/Löschung<br></br>
                </strong>
                Ihre personenbezogenen Angaben, die Sie im Rahmen der
                Kontaktaufnahme bei uns gemacht haben, speichern wir nur
                solange, wie dies nach dem Gesetz zulässig ist. Gerne teilen wir
                Ihnen auf Ihre Aufforderung hin mit, welche Daten wir zu Ihrer
                Person gespeichert haben. Sollte trotz unseres Bemühens um
                richtige und aktuelle Daten falsche Daten bei uns gespeichert
                sein, so berichtigen wir diese unverzüglich.
              </p>
              <p className="body">
                Sollten Sie die Löschung der gespeicherten Daten wünschen,
                erfolgt diese Löschung &shy;auf Ihre Anforderung hin ebenfalls
                unverzüglich. Ist eine Löschung aus rechtlichen Gründen nicht
                möglich, tritt an ihre Stelle eine Sperrung der jeweiligen
                Daten. Bitte beachten Sie jedoch, dass wir Ihnen bei Löschung
                Ihrer Daten die hier beschriebenen Services nicht mehr anbieten
                können. Bitte wenden Sie sich in diesen Fällen an:
              </p>
              <br></br>
              <p className="body">
                <strong>
                  9. Beauftragte Dienstleister<br></br>
                </strong>
                Die von der Kanzlei gemäß Art. 13 Abs. 1
                lit. e DS-GVO beauftragten Dienstleister sind:
                <ul role="list">
                  <li>Microsoft Outlook</li>
                  <li>Zapier</li>
                  <li>Anwalt.de</li>
                </ul>
                <strong>
                  Microsoft Outlook und Zapier<br></br>
                </strong>
                Zapier wird verwendet um die Informationen des Kontaktformulars an die Kanzlei zu übermitteln. Gleiches gilt für Microsoft Outlook.
                Zapier speichert personenbezogene Daten nur so lange, wie es für den Zweck erforderlich ist, für den sie gesammelt wurden. Sobald die Daten nicht mehr benötigt werden, werden sie automatisch gelöscht. Zapier stellt auch sicher, dass personenbezogene Daten verschlüsselt übertragen und gespeichert werden.
              </p>
              <p className="body"> </p>
              <br></br>
              <p className="body">
                Rechtsanwältin Elisabeth Lutz
              </p>
              <p className="body">
                Olgastraße 57a
              </p>
              <p className="body">
                70182 Stuttgart
              </p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Datenschutzerklaerung;
